import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EmailField,
    ResourceProps,
    DateField
} from 'react-admin';
import {CloverEmployeeFilterSidebar} from './CloverEmployeeFilterSidebar'

interface CloverEmployeeListProps extends ResourceProps {
}

const CloverEmployeeList: React.FC<CloverEmployeeListProps> = (props) => {
    return <List {...props} aside={<CloverEmployeeFilterSidebar/>}>
        <Datagrid bulkActionButtons={false}>

            <TextField source="id"/>

            <TextField source="uuid"/>

            <TextField source="name"/>

            <TextField source="nickname"/>

            <EmailField source="email"/>

            <DateField source="created_at" showTime />

            <DateField source="updated_at" showTime />

            <ShowButton/>
        </Datagrid>
    </List>
}

export default CloverEmployeeList;
