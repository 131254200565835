import {
    Datagrid,
    DateField,
    List,
    ReferenceField, ResourceProps,
    ShowButton,
    TextField,
} from 'react-admin';
import React from "react";
import CloverEcomOrderFilterSidebar from "./CloverEcomOrderFilterSidebar";
import {ReferencePolymorphicField} from "./ReferencePolymorphicField";
import PriceDisplayCurrencyContext from "./PriceDisplayCurrencyContext";
import TimestampField from './EpochDateTimeField';

interface CloverEcomOrderListProps extends ResourceProps {
}

export const CloverEcomOrderList = (props: CloverEcomOrderListProps) => (
    <List {...props} aside={<CloverEcomOrderFilterSidebar/>}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>

            <TextField source="id"/>

            <TextField source="uuid"/>

            <PriceDisplayCurrencyContext label={"amount"} sourceKey={"amount"}/>

            <TextField source="status"/>

            <ReferenceField source="clover_merchant_id" reference="clover-merchants" link="show">
                <TextField source="name"/>
            </ReferenceField>

            <ReferenceField label="Charge" source="clover_ecom_charge_id" reference="clover-ecom-charges" link="show">
                <TextField source="uuid"/>
            </ReferenceField>

            <ReferenceField source="clover_ecom_customer_id" reference="clover-ecom-customers">
                <TextField source="uuid"/>
            </ReferenceField>

            <ReferencePolymorphicField label={"Origin Order"} sourceKey={"origin_id"} typeKey={"origin_type"}/>

            <TimestampField source="clover_created_at"/>

            <DateField source="created_at" showTime />

            <DateField source="updated_at" showTime />

            <ShowButton/>
        </Datagrid>
    </List>
);
