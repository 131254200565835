import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    UrlField,
    ShowButton,
    ResourceProps
} from 'react-admin';
import {CloverMerchantFilterSidebar} from '../CloverMerchantFilterSidebar'

interface CloverMerchantListProps extends ResourceProps {
}

const CloverMerchantList = (props: CloverMerchantListProps) => {
    return <List {...props} aside={<CloverMerchantFilterSidebar/>}>
        <Datagrid bulkActionButtons={false}>

            <TextField source="id"/>

            <TextField source="uuid"/>

            <TextField source="name"/>

            <TextField source="phone_number"/>

            <UrlField source="website"/>

            <TextField source="alt_mid"/>

            <TextField source="mid"/>

            <TextField source="frontend_mid"/>

            <TextField source="backend_mid"/>

            <DateField source="created_at" showTime />

            <DateField source="updated_at" showTime />

            <ShowButton/>

        </Datagrid>
    </List>
}

export default CloverMerchantList;
