import React from 'react';
import {
    Show,
    TextField,
    DateField,
    SimpleShowLayout,
    SimpleShowLayoutProps,
    ReferenceField,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import PriceDisplayCurrencyContext from './PriceDisplayCurrencyContext';
import JsonField from './JsonField';
import TimestampField from './EpochDateTimeField';

interface CloverEcomOrderReturnShowProps extends SimpleShowLayoutProps {}

const CloverEcomOrderReturnShow = (props: CloverEcomOrderReturnShowProps) => {
    return (
        <Show>
            <SimpleShowLayout {...props}>
                <Typography variant="h6" component="h3" gutterBottom>
                    Clover Ecom Order Return
                </Typography>

                <TextField source="id" />
                <TextField source="uuid" />
                <PriceDisplayCurrencyContext label="amount" sourceKey="amount" />
                <PriceDisplayCurrencyContext label="amount_returned" sourceKey="amount_returned" />
                <TextField source="currency" />
                <TextField source="status" />
                <TextField source="external_reference_id" />
                <TimestampField source="returned_at" />
                <DateField source="created_at" showTime />
                <DateField source="updated_at" showTime />
                
                <JsonField source="items" label="Return Items" />

                <br />

                <Typography variant="h6" component="h3" gutterBottom>
                    Clover Relationships
                </Typography>

                <ReferenceField source="clover_ecom_order_id" reference="clover-ecom-orders" link="show">
                    <TextField source="uuid" />
                </ReferenceField>

                <ReferenceField source="clover_merchant_id" reference="clover-merchants" link="show">
                    <TextField source="name" />
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    );
};

export default CloverEcomOrderReturnShow; 