import React from 'react';
import {
    Show,
    TextField,
    DateField,
    SimpleShowLayout,
    ReferenceOneField,
    SimpleShowLayoutProps,
    ReferenceField,
    FunctionField,
    Link,
    ReferenceManyField,
    Datagrid,
    ShowButton,
    Pagination,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import PriceDisplayCurrencyContext from "./PriceDisplayCurrencyContext";
import {getEnvReceiptUrl} from "../EnvUtils";
import {CloverChargeReceiptIframe,} from "./CloverReceiptIframe";
import {RECEIPT_TYPES} from "../Constants";
import TimestampField from './EpochDateTimeField';

interface CloverEcomChargeShowProps extends SimpleShowLayoutProps {
}


const CloverEcomChargeShow = (props: CloverEcomChargeShowProps) => {
    return <Show>
        <SimpleShowLayout {...props}>
            <Typography variant="h6" component="h3" gutterBottom>
                CloverEcomCharge
            </Typography>

            <TextField source="id"/>

            <TextField source="uuid"/>

            <PriceDisplayCurrencyContext label={"amount"} sourceKey={"amount"}/>

            <TimestampField source="clover_created_at"/>

            <ReferenceField source="clover_ecom_refund_id" reference="clover_ecom_refunds" emptyText="Coming soon..."/>

            <DateField source="created_at" showTime />

            <DateField source="updated_at" showTime />

            <br/>

            <Typography variant="h6" component="h3" gutterBottom>
                Clover Relationships
            </Typography>

            <ReferenceField source="clover_merchant_id" reference="clover-merchants" emptyText={"Coming soon..."}
                            link="show">
                <TextField source="name"/>
            </ReferenceField>

            <ReferenceField source="clover_ecom_customer_id" reference="clover-ecom-customers">
                <TextField source="uuid"/>
            </ReferenceField>

            <ReferenceOneField label={"Clover Ecom Order"} target={"clover_ecom_charge_id"}
                               reference={"clover-ecom-orders"}
                               emptyText={"No charge found! Perhaps this order has not been paid yet!"} link={"show"}>
                <TextField source={"uuid"} label={"uuid"}/>
            </ReferenceOneField>

            <br/>

            <Typography variant="h6" component="h3" gutterBottom>
                Refunds
            </Typography>

            <ReferenceManyField 
                label="Charge Refunds" 
                reference="clover-charge-refunds" 
                target="clover_ecom_charge_id"
                pagination={<Pagination />} 
                perPage={5}
                emptyText="No refunds found for this charge"
            >
                <Datagrid bulkActionButtons={false}>
                    <TextField source="uuid" />
                    <PriceDisplayCurrencyContext label="amount" sourceKey="amount" />
                    <TextField source="status" />
                    <TextField source="refund_type" />
                    <TimestampField source="clover_created_at" />
                    <DateField source="created_at" showTime />
                    <DateField source="updated_at" showTime />
                    <ShowButton />
                </Datagrid>
            </ReferenceManyField>

            <FunctionField
                label={"Link to Clover Charge Receipt"}
                render={(record?: { uuid: string } | undefined) => {
                    if (!record || !record.uuid) return null;
                    return (
                        <div>
                            <div>
                                <Link to={getEnvReceiptUrl(record.uuid, RECEIPT_TYPES.CHARGE)}>
                                    View Official Clover Charge Receipt (Publicly Available)
                                </Link>
                            </div>
                            <CloverChargeReceiptIframe uuid={record.uuid}/>
                        </div>
                    );
                }}
            />

        </SimpleShowLayout>
    </Show>
}

export default CloverEcomChargeShow;
