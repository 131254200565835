import {
    BooleanField,
    Datagrid,
    DateField,
    List,
    ReferenceField,
    ResourceProps,
    ShowButton,
    TextField,
} from 'react-admin';
import React from "react";
import {EventFilterSidebar} from "./EventFilterSidebar";
import {
    ReferenceOnePolymorphicField,
} from "./ReferencePolymorphicField";

interface EventListProps extends ResourceProps {
}


export const EventList = (props: EventListProps) => (
    <List {...props} aside={<EventFilterSidebar/>}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <ReferenceOnePolymorphicField label={"Event Type"} sourceKey={"event_id"} typeKey={"eventable_type"}/>

            <ReferenceField source="integration_id" reference="integrations" link={"show"}>

                <TextField source={"uuid"}/><br/>
                <TextField source="domain"/>

            </ReferenceField>

            <TextField source="ip_address"/>

            <TextField source="user_agent"/>

            <BooleanField source="notification_sent" label="Notification Sent?" looseValue={true}/>

            <DateField source="created_at" showTime />

            <DateField source="updated_at" showTime />

            <ShowButton/>
        </Datagrid>
    </List>
);
