import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    ReferenceField,
    ResourceProps,
    ShowButton,
    TextField,
} from 'react-admin';
import CloverChargeRefundFilterSidebar from './CloverChargeRefundFilterSidebar';
import PriceDisplayCurrencyContext from './PriceDisplayCurrencyContext';
import TimestampField from './EpochDateTimeField';

interface CloverChargeRefundListProps extends ResourceProps {}

export const CloverChargeRefundList = (props: CloverChargeRefundListProps) => (
    <List {...props} aside={<CloverChargeRefundFilterSidebar />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="uuid" />
            <PriceDisplayCurrencyContext label="amount" sourceKey="amount" />
            <TextField source="status" />
            <TextField source="ref_num" />
            <TextField source="auth_code" />
            <TextField source="gateway_response_code" />
            <TextField source="refund_type" />
            
            <ReferenceField source="clover_ecom_charge_id" reference="clover-ecom-charges" link="show">
                <TextField source="uuid" />
            </ReferenceField>
            
            <ReferenceField source="clover_merchant_id" reference="clover-merchants" link="show">
                <TextField source="name" />
            </ReferenceField>
            
            <TimestampField source="clover_created_at" />
            <DateField source="created_at" showTime />
            <DateField source="updated_at" showTime />
            
            <ShowButton />
        </Datagrid>
    </List>
); 