import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    ReferenceField,
    ResourceProps,
    ShowButton,
    TextField,
} from 'react-admin';
import CloverEcomOrderReturnFilterSidebar from './CloverEcomOrderReturnFilterSidebar';
import PriceDisplayCurrencyContext from './PriceDisplayCurrencyContext';
import TimestampField from './EpochDateTimeField';

interface CloverEcomOrderReturnListProps extends ResourceProps {}

export const CloverEcomOrderReturnList = (props: CloverEcomOrderReturnListProps) => (
    <List {...props} aside={<CloverEcomOrderReturnFilterSidebar />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="uuid" />
            <PriceDisplayCurrencyContext label="amount" sourceKey="amount" />
            <PriceDisplayCurrencyContext label="amount_returned" sourceKey="amount_returned" />
            <TextField source="currency" />
            <TextField source="status" />
            <TextField source="external_reference_id" />
            <TimestampField source="returned_at" />
            
            <ReferenceField source="clover_ecom_order_id" reference="clover-ecom-orders" link="show">
                <TextField source="uuid" />
            </ReferenceField>
            
            <ReferenceField source="clover_merchant_id" reference="clover-merchants" link="show">
                <TextField source="name" />
            </ReferenceField>
            
            <DateField source="created_at" showTime />
            <DateField source="updated_at" showTime />
            
            <ShowButton />
        </Datagrid>
    </List>
); 