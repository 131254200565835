import React from 'react';
import {
    Show,
    TextField,
    DateField,
    SimpleShowLayout,
    SimpleShowLayoutProps,
    ReferenceField,
    BooleanField,
    NumberField,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import { ReferencePolymorphicField } from './ReferencePolymorphicField';
import TimestampField from './EpochDateTimeField';

interface CloverMeteredFeeShowProps extends SimpleShowLayoutProps {}

const CloverMeteredFeeShow = (props: CloverMeteredFeeShowProps) => {
    return (
        <Show>
            <SimpleShowLayout {...props}>
                <Typography variant="h6" component="h3" gutterBottom>
                    Clover Metered Fee
                </Typography>

                <TextField source="id" />
                <TextField source="uuid" />
                <TextField source="metered_fee_uuid" />
                <NumberField source="count" />
                <BooleanField source="success" />
                <TextField source="error_message" />
                <NumberField source="http_status_code" />
                <TimestampField source="clover_created_at" />
                <TimestampField source="clover_modified_at" />
                <DateField source="created_at" showTime />
                <DateField source="updated_at" showTime />
                <DateField source="deleted_at" showTime />

                <br />

                <Typography variant="h6" component="h3" gutterBottom>
                    Relationships
                </Typography>

                <ReferenceField source="merchant_id" reference="clover-merchants" link="show">
                    <TextField source="name" />
                </ReferenceField>

                <ReferenceField source="app_id" reference="clover-apps" link="show">
                    <TextField source="name" />
                </ReferenceField>

                <ReferenceField source="integration_id" reference="integrations" link="show">
                    <TextField source="name" />
                </ReferenceField>

                <Typography variant="h6" component="h3" gutterBottom>
                    Polymorphic Relationship
                </Typography>

                <TextField source="triggerable_type" label="Triggerable Type" />
                <TextField source="triggerable_id" label="Triggerable ID" />
                
                <ReferencePolymorphicField 
                    sourceKey="triggerable_id" 
                    typeKey="triggerable_type" 
                    label="Triggerable"
                />
            </SimpleShowLayout>
        </Show>
    );
};

export default CloverMeteredFeeShow; 