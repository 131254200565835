import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    ReferenceField,
    ResourceProps,
    ShowButton,
    TextField,
    BooleanField,
    NumberField,
} from 'react-admin';
import CloverMeteredFeeFilterSidebar from './CloverMeteredFeeFilterSidebar';
import { ReferencePolymorphicField } from './ReferencePolymorphicField';
import TimestampField from './EpochDateTimeField';

interface CloverMeteredFeeListProps extends ResourceProps {}

export const CloverMeteredFeeList = (props: CloverMeteredFeeListProps) => (
    <List {...props} aside={<CloverMeteredFeeFilterSidebar />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="uuid" />
            <TextField source="metered_fee_uuid" />
            <NumberField source="count" />
            <BooleanField source="success" />
            
            <ReferenceField source="merchant_id" reference="clover-merchants" link="show">
                <TextField source="name" />
            </ReferenceField>
            
            <ReferenceField source="app_id" reference="clover-apps" link="show">
                <TextField source="name" />
            </ReferenceField>
            
            <ReferenceField source="integration_id" reference="integrations" link="show">
                <TextField source="name" />
            </ReferenceField>
            
            <ReferencePolymorphicField 
                sourceKey="triggerable_id" 
                typeKey="triggerable_type" 
                label="Triggerable"
            />
            
            <TimestampField source="clover_created_at" />
            <TimestampField source="clover_modified_at" />
            <DateField source="created_at" showTime />
            <DateField source="updated_at" showTime />
            
            <ShowButton />
        </Datagrid>
    </List>
); 