import React from 'react';
import {
    Show,
    Datagrid,
    TextField,
    DateField,
    UrlField,
    SimpleShowLayout,
    EmailField,
    ReferenceManyField,
    SimpleShowLayoutProps,
    ShowButton,
    ReferenceField,
    Pagination,
    ReferenceOneField,
    NumberField,
    BooleanField
} from 'react-admin';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PriceDisplayCurrencyContext from "./PriceDisplayCurrencyContext";
import TimestampField from './EpochDateTimeField';

interface CloverMerchantShowProps extends SimpleShowLayoutProps {
}
const CloverMerchantShow = (props: CloverMerchantShowProps) => {
    return <Show>
        <SimpleShowLayout {...props}>
            <Typography variant="h6" component="h3" gutterBottom>
                Clover Merchant
            </Typography>

            <TextField source="name"/>

            <TextField source="uuid"/>

            <TextField source="phone_number"/>

            <UrlField source="website"/>

            <TextField source="alt_mid"/>

            <TextField source="mid"/>

            <TextField source="frontend_mid"/>

            <TextField source="backend_mid"/>

            <DateField source="created_at"/>

            <DateField source="updated_at"/>

            <Box sx={{marginTop: '16px',}}>
                <Typography variant="h6" component="h3" gutterBottom>
                    Owner
                </Typography>


                <ReferenceOneField label="Owner" reference="clover-merchant-owners" target="merchant_id">
                    <SimpleShowLayout>
                        <TextField source="owner_name"/>
                        <EmailField source="owner_email"/>
                    </SimpleShowLayout>
                </ReferenceOneField>
                <br/>

                <Typography variant="h6" component="h3" gutterBottom>
                    Clover SaaS Plan
                </Typography>

                <ReferenceOneField label="SaaS plan" reference="clover-merchant-saas-plans" target="merchant_id">
                    <ReferenceField label={"Plan Name"} source="plan_id" reference="clover-saas-plans">
                        <SimpleShowLayout>

                            <TextField label={"Plan Name"} source="name"/>
                            <TextField label={"Plan Code"} source="code"/>
                            <TextField label={"Plan Type"} source="type"/>

                        </SimpleShowLayout>
                    </ReferenceField>
                </ReferenceOneField>

                <br/>
                <br/>

                <Typography variant="h6" component="h3" gutterBottom>
                    Clover Employees
                </Typography>
                <label>Available soon...</label>
                <br/>
                <br/>

                <Typography variant="h6" component="h3" gutterBottom>
                    Integrations
                </Typography>

                <ReferenceManyField label="Integrations" reference="integrations" target="clover_merchant_id">
                    <Datagrid bulkActionButtons={false}>

                        <UrlField source="domain" label="Website"/>
                        <TextField source="uuid"/>
                        <ShowButton/>

                    </Datagrid>
                </ReferenceManyField>
                <br/>

                <Typography variant="h6" component="h3" gutterBottom>
                    Clover Ecom Orders
                </Typography>

                <ReferenceManyField label="Clover Ecom Orders" reference="clover-ecom-orders"
                                    target="clover_merchant_id" pagination={<Pagination/>} perPage={5}>
                    <Datagrid bulkActionButtons={false}>

                        <TextField source="uuid"/>
                        <PriceDisplayCurrencyContext label={"amount"} sourceKey={"amount"}/>
                        <TextField source="status"/>
                        <DateField source="created_at" showTime />
                        <DateField source="updated_at" showTime />
                        <ShowButton/>

                    </Datagrid>
                </ReferenceManyField>

                <Typography variant="h6" component="h3" gutterBottom>
                    Charge Refunds
                </Typography>

                <ReferenceManyField 
                    label="Charge Refunds" 
                    reference="clover-charge-refunds"
                    target="clover_merchant_id" 
                    pagination={<Pagination/>} 
                    perPage={5}
                >
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="uuid"/>
                        <PriceDisplayCurrencyContext label="amount" sourceKey="amount"/>
                        <TextField source="status"/>
                        <TextField source="refund_type"/>
                        <TimestampField source="clover_created_at" />
                        <DateField source="created_at" showTime />
                        <DateField source="updated_at" showTime />
                        <ShowButton/>
                    </Datagrid>
                </ReferenceManyField>
                <br/>

                <Typography variant="h6" component="h3" gutterBottom>
                    Order Returns
                </Typography>

                <ReferenceManyField 
                    label="Order Returns" 
                    reference="clover-ecom-order-returns"
                    target="clover_merchant_id" 
                    pagination={<Pagination/>} 
                    perPage={5}
                >
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="uuid"/>
                        <PriceDisplayCurrencyContext label="amount" sourceKey="amount"/>
                        <PriceDisplayCurrencyContext label="amount_returned" sourceKey="amount_returned"/>
                        <TextField source="status"/>
                        <TimestampField source="returned_at" />
                        <DateField source="created_at" showTime />
                        <DateField source="updated_at" showTime />
                        <ShowButton/>
                    </Datagrid>
                </ReferenceManyField>
                
                <Typography variant="h6" component="h3" gutterBottom>
                    Metered Fees
                </Typography>

                <ReferenceManyField 
                    label="Metered Fees" 
                    reference="clover-metered-fees"
                    target="merchant_id" 
                    pagination={<Pagination/>} 
                    perPage={5}
                >
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="uuid"/>
                        <TextField source="metered_fee_uuid"/>
                        <NumberField source="count"/>
                        <BooleanField source="success"/>
                        <TextField source="triggerable_type"/>
                        <TimestampField source="clover_created_at" />
                        <DateField source="created_at" showTime />
                        <DateField source="updated_at" showTime />
                        <ShowButton/>
                    </Datagrid>
                </ReferenceManyField>
            </Box>

        </SimpleShowLayout>
    </Show>
}

export default CloverMerchantShow;
