import React from 'react';
import {QueryClient} from 'react-query';
import {Admin, Resource, fetchUtils} from 'react-admin';
import CloverMerchantList from './components/CloverMerchantList';
import {MyLayout} from './MyLayout';
import authProvider from './AuthProvider';
import simpleRestProvider from 'ra-data-simple-rest';
import CloverMerchantShow from "./components/CloverMerchantShow";
import {IntegrationList} from "./components/IntegrationsList";
import IntegrationShow from "./components/IntegrationShow";
import {IntegrationEdit} from "./components/IntegrationEdit";
import CloverEmployeeList from "./components/CloverEmployeeList";
import CloverEmployeeShow from "./components/CloverEmployeeShow";
import {CloverEcomOrderList} from "./components/CloverEcomOrderList";
import CloverEcomOrderShow from "./components/CloverEcomOrderShow";
import {CloverEcomChargeList} from "./components/CloverEcomChargeList";
import CloverEcomChargeShow from "./components/CloverEcomChargeShow";
import {EventList} from './components/EventList';
import EventShow from "./components/EventShow";
import {CloverApiErrorList} from "./components/CloverApiErrorList";
import CloverApiErrorShow from "./components/CloverApiErrorShow";
import {getEnvApiUrl} from "./EnvUtils";
import {CloverChargeRefundList} from "./components/CloverChargeRefundList";
import CloverChargeRefundShow from "./components/CloverChargeRefundShow";
import {CloverEcomOrderReturnList} from "./components/CloverEcomOrderReturnList";
import CloverEcomOrderReturnShow from "./components/CloverEcomOrderReturnShow";
import {CloverMeteredFeeList} from "./components/CloverMeteredFeeList";
import CloverMeteredFeeShow from "./components/CloverMeteredFeeShow";

const App = () => {

    const apiUrl: string = getEnvApiUrl();

    const httpClient = (url: any, options: fetchUtils.Options = {}) => {
        const headers = new Headers(options.headers);
        headers.set('Content-Type', 'application/json');
        headers.set('Accept', 'application/json');
        options.credentials = 'include';
        return fetchUtils.fetchJson(url, {...options, headers});
    };
    const dataProvider = simpleRestProvider('https://' + apiUrl + '/v1/admin', httpClient);

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 60 * 1000, // 1 minute
            },
        },
    });

    return (
        <Admin dataProvider={dataProvider} layout={MyLayout} authProvider={authProvider} queryClient={queryClient}>
            <Resource name="integrations" list={IntegrationList} show={IntegrationShow} edit={IntegrationEdit}/>
            <Resource name="clover-merchants" list={CloverMerchantList} show={CloverMerchantShow}/>
            <Resource name="clover-employees" list={CloverEmployeeList} show={CloverEmployeeShow}/>
            <Resource name="clover-ecom-orders" list={CloverEcomOrderList} show={CloverEcomOrderShow}/>
            <Resource name="clover-ecom-charges" list={CloverEcomChargeList} show={CloverEcomChargeShow}/>
            <Resource name="clover-charge-refunds" list={CloverChargeRefundList} show={CloverChargeRefundShow}/>
            <Resource name="clover-ecom-order-returns" list={CloverEcomOrderReturnList} show={CloverEcomOrderReturnShow}/>
            <Resource name="clover-metered-fees" list={CloverMeteredFeeList} show={CloverMeteredFeeShow}/>
            <Resource name="events" list={EventList} show={EventShow}/>
            <Resource name="clover-api-errors" list={CloverApiErrorList} show={CloverApiErrorShow}/>
        </Admin>
    );
}

export default App;
