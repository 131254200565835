import React from 'react';
import { useRecordContext } from 'react-admin';
import { Box, Typography } from '@mui/material';

interface JsonFieldProps {
    source: string;
    label?: string;
}

const JsonField: React.FC<JsonFieldProps> = ({ source, label }) => {
    const record = useRecordContext();
    
    if (!record || !record[source]) return null;
    
    const jsonData = record[source];
    
    return (
        <Box sx={{ mb: 2 }}>
            {label && <Typography variant="subtitle2">{label}</Typography>}
            <Box 
                component="pre" 
                sx={{ 
                    backgroundColor: '#f5f5f5', 
                    padding: 2, 
                    borderRadius: 1,
                    overflow: 'auto',
                    maxHeight: '300px',
                    fontSize: '0.875rem'
                }}
            >
                {JSON.stringify(jsonData, null, 2)}
            </Box>
        </Box>
    );
};

export default JsonField; 