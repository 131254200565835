import { Edit, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

export const IntegrationEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="type" disabled />
            <TextInput source="domain" disabled />
            <TextInput source="uuid" disabled />
            <TextInput source="backend_type" disabled />
            <TextInput source="id" disabled />
            <TextInput source="integrable_type" disabled />
            {/* No resource for integrables */}
            {/* <ReferenceInput source="integrable_id" reference="integrables" /> */}
            <TextInput source="deleted_at" disabled />
            <TextInput source="created_at" disabled />
            <TextInput source="updated_at" disabled />
            
            {/* Active references from App.tsx */}
            <ReferenceInput source="clover_app_id" reference="clover-apps">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="clover_merchant_id" reference="clover-merchants">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="clover_employee_id" reference="clover-employees">
                <SelectInput optionText="name" />
            </ReferenceInput>

            <TextInput source="integrable_backend_type" disabled />
            {/* No resource for integrable_backends */}
            {/* <ReferenceInput source="integrable_backend_id" reference="integrable_backends" /> */}
        </SimpleForm>
    </Edit>
); 