import { SavedQueriesList, FilterLiveSearch } from 'react-admin';
import { Card, CardContent } from '@mui/material';

// @ts-ignore
const CloverChargeRefundFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 200 }}>
        <CardContent>
            <SavedQueriesList />
            <FilterLiveSearch source="uuid" />
            <FilterLiveSearch source="ref_num" />
            <FilterLiveSearch source="auth_code" />
            <FilterLiveSearch source="status" />
        </CardContent>
    </Card>
);

export default CloverChargeRefundFilterSidebar; 