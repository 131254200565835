import React from 'react';
import {
    Show,
    TextField,
    DateField,
    SimpleShowLayout,
    SimpleShowLayoutProps,
    ReferenceField,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import PriceDisplayCurrencyContext from './PriceDisplayCurrencyContext';
import JsonField from './JsonField';
import TimestampField from './EpochDateTimeField';

interface CloverChargeRefundShowProps extends SimpleShowLayoutProps {}

const CloverChargeRefundShow = (props: CloverChargeRefundShowProps) => {
    return (
        <Show>
            <SimpleShowLayout {...props}>
                <Typography variant="h6" component="h3" gutterBottom>
                    Clover Charge Refund
                </Typography>

                <TextField source="id" />
                <TextField source="uuid" />
                <PriceDisplayCurrencyContext label="amount" sourceKey="amount" />
                <TextField source="status" />
                <TextField source="ref_num" />
                <TextField source="auth_code" />
                <TextField source="gateway_response_code" />
                <TextField source="refund_type" />
                <TimestampField source="clover_created_at" />
                <DateField source="created_at" showTime />
                <DateField source="updated_at" showTime />
                
                <JsonField source="metadata" label="Metadata" />

                <br />

                <Typography variant="h6" component="h3" gutterBottom>
                    Clover Relationships
                </Typography>

                <ReferenceField source="clover_ecom_charge_id" reference="clover-ecom-charges" link="show">
                    <TextField source="uuid" />
                </ReferenceField>

                <ReferenceField source="clover_merchant_id" reference="clover-merchants" link="show">
                    <TextField source="name" />
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    );
};

export default CloverChargeRefundShow; 