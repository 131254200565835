import React from 'react';
import { DateField, useRecordContext } from 'react-admin';

interface TimestampFieldProps {
    source: string;
    label?: string;
    showTime?: boolean;
}

/**
 * Field component to display a date/time from either:
 * 1. An epoch timestamp (milliseconds)
 * 2. An ISO 8601 formatted string
 * 
 * Automatically detects the format and converts as needed before passing to DateField
 */
const TimestampField: React.FC<TimestampFieldProps> = ({ source, label, showTime = true }) => {
    const record = useRecordContext();
    
    if (!record || !record[source]) return null;
    
    const value = record[source];
    let transformedDate;
    
    // Check if the value is a number or a numeric string (epoch timestamp)
    if (!isNaN(Number(value)) && !isNaN(parseFloat(value))) {
        transformedDate = new Date(Number(value));
    } 
    // Otherwise, assume it's already a date string (ISO 8601)
    else {
        transformedDate = new Date(value);
    }
    
    // Create a new record with the transformed date
    const newRecord = {
        ...record,
        [source]: transformedDate
    };
    
    return (
        <DateField source={source} label={label} showTime={showTime} record={newRecord} />
    );
};

export default TimestampField; 