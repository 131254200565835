import { SavedQueriesList, FilterLiveSearch } from 'react-admin';
import { Card, CardContent } from '@mui/material';

// @ts-ignore
const CloverEcomOrderReturnFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 200 }}>
        <CardContent>
            <SavedQueriesList />
            <FilterLiveSearch source="uuid" />
            <FilterLiveSearch source="status" />
            <FilterLiveSearch source="external_reference_id" />
        </CardContent>
    </Card>
);

export default CloverEcomOrderReturnFilterSidebar; 